/**
 * Reformat the Case Study object for use on the ArticleGrid component
 * @param  {Array} caseStudyNodes
 * @return {Array}
 */
const caseStudyDecorator = (caseStudyNodes) => {
  return caseStudyNodes.map((caseStudy) => {
    return {
      url: caseStudy.url,
      // Pull the image from the platform
      image: {
        url: caseStudy.data.embed.thumbnail_url,
        dimensions: {
          width: 960,
          height: 540
        }
      },
      category: 'Case Study',
      title: caseStudy.data.title.text,
      body: caseStudy.data.intro.text,
      avatar: caseStudy.data.company_logo,
      name: null,
      role: null,
      fallbackAlt: caseStudy.data.title.text,
      isCompanyLogo: true
    }
  })
}

export default caseStudyDecorator
