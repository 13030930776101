import React from 'react'
import PropTypes from 'prop-types'

// Utils
import caseStudyDecorator from '../../../utils/caseStudyDecorator'

// Local
import CaseStudyTestimonialView from './CaseStudyTestimonialView'

const CaseStudyView = (props) => {
  const { data } = props

  return (
    <CaseStudyTestimonialView
      data={data.prismicCaseStudy.data}
      relatedItems={caseStudyDecorator(data.moreCaseStudies.nodes)}
      type='case_study'
      url={data.prismicCaseStudy.url}
    />
  )
}

CaseStudyView.propTypes = {
  data: PropTypes.object.isRequired
}

export default CaseStudyView
